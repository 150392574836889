import React from "react"
import Layout from "../components/Layout"
import { Button } from "../styles/ui"
import Article from "../components/Article"
import Map from "../components/Map"
import { CenteredBox } from "../styles/ui"
import StickyCTA from "../components/StickyCTA"
import { email } from "../content/content"
import fullsize5 from "../images/gallery/hq5.jpg"
import fullsize6 from "../images/gallery/hq6.jpg"
import fullsize9 from "../images/gallery/hq9.jpg"
import fullsize10 from "../images/gallery/hq10.jpg"
import fullsize11 from "../images/gallery/hq11.jpg"
import fullsize16 from "../images/gallery/hq16.jpg"
import fullsize17 from "../images/gallery/hq17.jpg"
import fullsize18 from "../images/gallery/hq18.jpg"
import fullsize19 from "../images/gallery/hq19.jpg"
import fullsize20 from "../images/gallery/hq20.jpg"
import thumbnail5 from "../images/thumbnails/hq5.jpg"
import thumbnail6 from "../images/thumbnails/hq6.jpg"
import thumbnail9 from "../images/thumbnails/hq9.jpg"
import thumbnail10 from "../images/thumbnails/hq10.jpg"
import thumbnail11 from "../images/thumbnails/hq11.jpg"
import thumbnail16 from "../images/thumbnails/hq16.jpg"
import thumbnail17 from "../images/thumbnails/hq17.jpg"
import thumbnail18 from "../images/thumbnails/hq18.jpg"
import thumbnail19 from "../images/thumbnails/hq19.jpg"
import thumbnail20 from "../images/thumbnails/hq20.jpg"
import studio1 from "../images/half/hq2.jpg"
import studio2 from "../images/half/gear.jpg"
import studio3 from "../images/half/productPhoto.jpg"
import studioBanner from "../images/banner/studio_banner2.jpg"
import Gallery from "../components/Gallery"
import IconList from "../components/IconList"
import {
  FaHeadphones,
  FaTshirt,
  FaGuitar,
  FaPalette,
  FaPhotoVideo,
  FaUserAstronaut,
  FaGifts,
  FaMapMarkerAlt,
  FaChalkboardTeacher,
  FaPizzaSlice,
  FaMicrophone,
} from "react-icons/fa"
import Section from "../components/Section"
import { darken, lighten } from "polished"
import theme from "../styles/theme"
import Banner from "../components/Banner"
import HalfBlock from "../components/HalfBlock"
import SEOpt from "../components/SEOpt"
import { Link as ScrollLink } from "react-scroll"
import { getMenuHeight } from "../components/NavBar"
import Cards from "../components/Cards"
import WrapFadeUp from "../components/WrapFadeUp"

const iconListItems = [
  {
    text: "music videos",
    icon: <FaGuitar />,
  },
  {
    text: "DJ live sets",
    icon: <FaHeadphones />,
  },
  {
    text: "workshops",
    icon: <FaChalkboardTeacher />,
  },
  {
    text: "short films",
    icon: <FaPhotoVideo />,
  },
  {
    text: "creative photography",
    icon: <FaPalette />,
  },
  {
    text: "fashion photography",
    icon: <FaTshirt />,
  },
  {
    text: "product photography",
    icon: <FaGifts />,
  },
  {
    text: "portrait photography",
    icon: <FaUserAstronaut />,
  },
  {
    text: "interviews",
    icon: <FaMicrophone />,
  },
  {
    text: "food photography",
    icon: <FaPizzaSlice />,
  },
]

const galleryItems = [
  {
    alt: "Photoshoot with @rickyd1337",
    fullsize: fullsize6,
    thumbnail: thumbnail6,
  },
  {
    alt: "Projectors and smoke",
    fullsize: fullsize9,
    thumbnail: thumbnail9,
  },
  {
    alt: "Levi and the Portal",
    fullsize: fullsize10,
    thumbnail: thumbnail10,
  },
  {
    alt: "The ladder",
    fullsize: fullsize11,
    thumbnail: thumbnail11,
  },
  {
    alt: "Tamara taking a picture of the aforementioned ladder",
    fullsize: fullsize19,
    thumbnail: thumbnail19,
  },
  {
    alt: "Fede taking a picture of wall",
    fullsize: fullsize5,
    thumbnail: thumbnail5,
  },
  {
    alt: "Tamara and Miruna in the dark",
    fullsize: fullsize16,
    thumbnail: thumbnail16,
  },
  {
    alt: "Levi",
    fullsize: fullsize17,
    thumbnail: thumbnail17,
  },
  {
    alt: "Tamara",
    fullsize: fullsize18,
    thumbnail: thumbnail18,
  },
  {
    alt: "More projectors and smoke",
    fullsize: fullsize20,
    thumbnail: thumbnail20,
  },
]

const pricing = [
  {
    title: "35",
    text: "1 hour",
  },
  {
    title: "150",
    text: "5 hours",
  },
  {
    title: "250",
    text: "1 day",
  },
]

const bannerImage = {
  src: studioBanner,
  altText: "Rocketship Studio",
}

const HalfBlock1 = () => (
  <React.Fragment>
    <ul className="ul-spaced col-count-2">
      <WrapFadeUp>
        <li>green screen</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>black backdrop</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>JIB crane</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>easy rig</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>studio lights</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>tripods</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>projectors</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>smoke machine</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>speakers</li>
      </WrapFadeUp>
    </ul>
  </React.Fragment>
)

const HalfBlock2 = () => (
  <React.Fragment>
    <ul className="ul-spaced">
      <WrapFadeUp>
        <li>over 150m&sup2; of usable space</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>white backdrop corner 5m x 3m</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>natural light through the glass ceiling</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>wide garage door for easy loading and unloading</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>near metro station van der Madeweg in Amsterdam</li>
      </WrapFadeUp>
    </ul>
  </React.Fragment>
)

const HalfBlock3 = () => (
  <div>
    <ul className="ul-spaced">
      <WrapFadeUp>
        <li>tabletop photo studio for product photography</li>
      </WrapFadeUp>
      <WrapFadeUp>
        <li>props and set dressing paraphernalia</li>
      </WrapFadeUp>
    </ul>
  </div>
)

const Studio = () => {
  return (
    <Layout>
      <SEOpt title={"Film & photo studio for rent in Amsterdam"} />
      <Banner {...bannerImage} />
      <Section background={darken(0.03, theme.bg)}>
        <Article>
          <WrapFadeUp>
            <Button
              as="a"
              className="price-tag-btn"
              href={`mailto:${email}?subject=Book Rocketship Studio`}
            >
              Book from &euro; 35 / hour
            </Button>

            <h1>Modern film &amp; photo studio in the heart of Amsterdam</h1>
          </WrapFadeUp>

          <WrapFadeUp>
            <p className="subhead">
              <ScrollLink
                to="mapWrapperSection"
                smooth={true}
                duration={500}
                offset={-getMenuHeight()}
                href="#mapWrapperSection"
              >
                <FaMapMarkerAlt className="icon-inline" />
                van der Madeweg, Amsterdam
              </ScrollLink>
            </p>
          </WrapFadeUp>

          <WrapFadeUp>
            <p>
              Our studio is a 150m&sup2; creative space with access to a green
              screen, photography backgrounds and a tabletop photo studio for
              product photography.
            </p>
          </WrapFadeUp>
          <WrapFadeUp>
            <p>
              On location we have a variety of equipment that can be used for
              free (tripods, studio lights, JIB crane). The studio is also
              equipped with high powered projectors and speakers for background
              visuals and sound.
            </p>
          </WrapFadeUp>
          <WrapFadeUp>
            <p>
              We encourage our clients to bring their own equipment, however for
              an added fee we can arrange for Blackmagic cameras and other gear
              to be ready on site (please reserve 1 week in advance). We can
              also provide cameramen, editors and other staff for your
              production.
            </p>
          </WrapFadeUp>
          <WrapFadeUp>
            <p>
              If you're interested, get in touch with us via{" "}
              <a href={`mailto:${email}`}>info@rocketshiptrips.com</a>.
            </p>
          </WrapFadeUp>
        </Article>
      </Section>
      <Section>
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h2>Use our studio for</h2>
            </WrapFadeUp>
          </div>
          <IconList items={iconListItems} />
        </CenteredBox>
      </Section>
      <HalfBlock
        fullwidth
        background="#31353a"
        imgSrc={studio1}
        title="Location features"
        textBlock={<HalfBlock2 />}
      />
      <HalfBlock
        fullwidth
        imgSrc={studio2}
        titleLarger="Available gear"
        textBlock={<HalfBlock1 />}
        imgOnRight
      />
      <HalfBlock
        background={lighten(0.1, theme.bg)}
        fullwidth
        imgSrc={studio3}
        title="Product photography set"
        textBlock={<HalfBlock3 />}
      />
      <Section background={darken(0.02, theme.bg)}>
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h1>Pricing</h1>
            </WrapFadeUp>
          </div>
          <Cards items={pricing} pricing />
        </CenteredBox>
      </Section>
      <Section noPaddingBottom>
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h1>Gallery</h1>
            </WrapFadeUp>
          </div>
        </CenteredBox>
        <Gallery items={galleryItems} />
      </Section>
      <Section
        background={darken(0.05, theme.bg)}
        noPaddingBottom
        id="mapWrapperSection"
      >
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h1>Location</h1>
            </WrapFadeUp>
          </div>
        </CenteredBox>
        <Map />
      </Section>
      <StickyCTA
        text="Love our studio?"
        buttonText="Book now"
        subject="Book Rocketship Studio"
      />
    </Layout>
  )
}

export default Studio
